<template>
  <div>
    <MallheaderVue
      :titlename="'商品'"
      :customstyle="{ background: '#FFFFFF', position: 'relative' }"
    >
      <template slot="headbody">
        <div class="searchbox">
          <div class="searchinput">
            <img
              class="searchicon"
              :src="require('@/assets/images/mall/goods/searchicon.png')"
            />
            <input
              v-model="searchkey"
              class="inputtxt"
              type="text"
              placeholder="请输入商品名称进行搜索"
            />
          </div>
          <img
            @click="openmenu"
            class="menuicon"
            :src="require('@/assets/images/mall/goods/openclass.png')"
          />
        </div>
        <div class="cate" v-if="showcate">
          <div class="catebg">
            <div class="pricebox">
              <div class="title">价格</div>
              <div class="priceorder">
                <div
                  @click="selectprice('price desc')"
                  :class="
                    price == 'price desc'
                      ? 'highselect marginR24'
                      : 'nomalselect marginR24'
                  "
                >
                  高
                </div>
                <div
                  @click="selectprice('price asc')"
                  :class="price == 'price asc' ? 'highselect' : 'nomalselect'"
                >
                  低
                </div>
              </div>
            </div>
            <div class="classification">
              <div class="title">分类</div>
              <div class="classifi">
                <div
                  :class="cate_id == menu.id ? 'menuselectitem' : 'menuitem'"
                  v-for="(menu, index) in cateList"
                  :key="index"
                  @click="selectcate(menu.id)"
                >
                  <img class="menuicon" :src="menu.image" />
                  <div class="menutitle">{{ menu.name }}</div>
                </div>
              </div>
            </div>
            <div class="btnbox">
              <div class="btn" @click="clicksuremenu">确 认</div>
            </div>
          </div>
        </div>
      </template>
    </MallheaderVue>

    <div class="allgoods">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="listcontent">
            <div @click="godetail(item)" class="item" v-for="(item, index) in list" :key="index">
              <img class="pic" :src="item.images" />
              <div class="info">
                <div class="title van-multi-ellipsis--l2">
                  {{ item.name }}
                </div>
                <div class="bottom">
                  <div class="price">
                    <span class="num">
                      {{ parseFloat(item.price).toFixed(3) }}</span
                    >
                    <span class="unit">/USD</span>
                  </div>
                  <div class="see" >查看 ></div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { goodsList, getCateList } from "@/request/shopapi";
import MallheaderVue from "../../components/mallheader.vue";
export default {
  name: "EtWebGoodslist",
  components: {
    MallheaderVue,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      searchkey: "",
        currentPage: 1,
      cate_id: this.cate_id,
      price: "price asc",
      cateList: [],
      showcate: false,
    };
  },

  mounted() {},
  activated() {
    this.cate_id = "";
    this.searchkey = "";
    this.price = "";
    this.requestgetCateList();
    // this.onRefresh();
  },
  methods: {
    selectcate(cid) {
      console.log(cid);
      this.cate_id = cid;
    },
    selectprice(ord) {
      this.price = ord;
    },
    openmenu() {
      this.showcate = !this.showcate;
    },
    requestgetCateList() {
      getCateList().then((res) => {
        this.cateList = res.data;
      });
    },
    requestGoodslist() {
      const param = {
        page: this.currentPage,
        limit: 20,
        name: this.searchkey,
        cate_id: this.cate_id,
        price: this.price,
      };
      goodsList(param).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
        }
        this.loading = false;
          this.list = [...this.list, ...res.data.list];
          if (res.data.list.length < 20) {
              this.finished = true;
          }
      });
    },
    clicksuremenu() {
      this.showcate = false;
        this.list = [];
        this.onRefresh();
    },
      onRefresh() {
          // 清空列表数据
          this.finished = false;
          this.currentPage = 1;
          // 重新加载数据
          // 将 loading 设置为 true，表示处于加载状态
          this.loading = true;
          this.onLoad();
      },
      onLoad() {
          this.requestGoodslist();
          this.currentPage++;
      },
    godetail(item) {
      this.$router.push({
        path: "/mall/goodsdetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.marginR24 {
  margin-right: 24px;
}
.searchbox {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  .searchinput {
    width: 614px;
    height: 64px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    padding: 0 32px;
    display: flex;
    align-items: center;
    .searchicon {
      width: 34.29px;
      height: 32px;
    }
    .inputtxt {
      width: 500px;
      color: black;
      font-size: 28px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: left;
      border-width: 0px;
      height: 64px;
      background-color: transparent;
    }
  }
  .menuicon {
    width: 64px;
    height: 64px;
  }
}
.cate {
  position: absolute;
  z-index: 999;
  background: rgba(41, 33, 29, 0.75);
  min-height: 100vh;
  .catebg {
    padding: 0 24px;

    width: 750px;
    min-height: 532px;
    border-radius: 0 0 24px 24px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
  }
  .pricebox {
    .title {
      color: rgba(0, 0, 0, 0.9);
      font-size: 24px;
      font-weight: 700;
      font-family: "PingFang SC";
      line-height: 36px;
    }
    .priceorder {
      margin-top: 16px;
      display: flex;
      font-size: 28px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 64px;
      .highselect {
        width: 206px;
        height: 64px;
        border-radius: 8px;
        opacity: 1;
        border: 2px solid rgba(255, 114, 32, 1);
        background: rgba(255, 247, 242, 1);
        color: rgba(255, 114, 32, 1);
      }
      .nomalselect {
        width: 206px;
        height: 64px;
        border-radius: 8px;
        opacity: 1;
        background: rgba(250, 247, 245, 1);
        color: rgba(105, 98, 95, 1);
      }
    }
  }
  .classification {
    margin-top: 24px;
    .title {
      color: rgba(0, 0, 0, 0.9);
      font-size: 24px;
      font-weight: 700;
      font-family: "PingFang SC";
      line-height: 36px;
    }
    .classifi {
      margin-top: 16px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .menuselectitem {
        width: 156px;
        height: 124px;
        border-radius: 8px;
        opacity: 1;
        border: 2px solid rgba(255, 114, 32, 1);
        background: rgba(255, 247, 242, 1);
        color: rgba(255, 114, 32, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .menuitem {
        width: 156px;
        height: 124px;
        border-radius: 8px;
        opacity: 1;
        background: rgba(250, 247, 245, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgba(105, 98, 95, 1);
      }
      .menuicon {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        opacity: 1;
        background: rgba(217, 217, 217, 1);
      }
      .menutitle {
        margin-top: 8px;
      }
    }
  }
  .btnbox {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    .btn {
      width: 478px;
      height: 72px;
      border-radius: 74px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      color: rgba(238, 238, 238, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 72px;
    }
  }
}
.allgoods {
  background: #ebeef2;
  padding: 0 24px;
  min-height: 100vh;

  .listcontent {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      margin-bottom: 24px;
      width: 340px;
      height: 500px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      .pic {
        width: 340px;
        height: 340px;
        border-radius: 24px 24px 0 0;
        opacity: 1;
        background: orange;
      }
      .info {
        padding: 16px;
        .title {
          color: rgba(41, 33, 29, 1);
          font-size: 30px;
          font-weight: 600;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
        .bottom {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .price {
            font-size: 14px;
            .num {
              color: rgba(255, 114, 32, 1);
              font-weight: 500;
              font-family: "DIN";
              text-align: left;
              line-height: 20px;
              font-size: 14px;
            }
            .unit {
              color: rgba(255, 114, 32, 1);
              font-weight: 500;
              font-family: "DIN";
              text-align: left;
              line-height: 20px;
              font-size: 10px;
            }
          }
          .see {
            color: rgba(156, 152, 150, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 32px;
          }
        }
      }
    }
  }
}
</style>
